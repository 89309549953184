<script>
import Layout from "../../layouts/main";
import YearLayout from '../../layouts/yearLayout';
import errorHandler from '@/helpers/errorHandler'
// import moment from 'moment';

/**
 * Starter component
 */
export default {
  page: {
    title: "Stage Info",
  },
  name: "details",
  components:{
    Layout,
    YearLayout
  }, 
  async created(){
    await this.$store.dispatch('year/getYearInfo',this.$route.params.year).then(()=>{
        this.profileData = this.$store.getters['year/getYear'];
    }).catch( err =>{
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    });
  },
  data() {
    return {
      profileData:{}
    };
  },
//   filters:{
//     formatDate(value){
//       if(value){
//         return moment(String(value)).format('hh:mm A DD/MM/YYYY')
//       }
//     }
//   }
};
</script>

<template>
  <Layout>
    <YearLayout :year="profileData">
        <div>
            <h5 class="mb-3 text-uppercase bg-light p-2">
                <i class="mdi mdi-account-circle mr-1 "></i> Basic Info
            </h5>
            <table class="table table-borderless table-sm">
            <tbody class="row">
                <tr class="col-md-6">
                <th scope="row">Level:</th>
                <td v-if="profileData.number">
                    {{ profileData.number}}
                </td>
                </tr>
                <tr class="col-md-6">
                <th scope="row">Stage:</th>
                <td v-if="profileData.description">
                    {{ profileData.description}}
                </td>
                </tr>
            </tbody>
            </table>
            <b-container class="bv-example-row bv-example-row-flex-cols">
            <b-row>
                <b-col class="col-md-2 offset-md-10 offset-0">
                <router-link :to="'/years/'+this.$route.params.year+'/edit'">
                    <b-button variant="outline-primary" pill>
                    <i class="fas fa-pen mr-1"></i>
                    Edit 
                    </b-button>
                </router-link>
                </b-col>
            </b-row>
            </b-container>
            
        </div>
    </YearLayout>
  </Layout>
</template>

<style scoped>
ul {
  list-style-type: none;
}

.tab-content {
  border: none;
}

.list-group-item {
  border: none;
}

i {
  font-size: 20px;
}
</style>
